import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { css } from '@emotion/core'
import '../theme/bootstrap-grid.css'
import {
  Box,
  Blockquote,
  Button,
  ContactForm,
  Container,
  Flex,
  GoogleMap,
  Heading,
  Image,
  Layout,
  Link,
  PlayButton,
  ProductLine,
  Text,
  LargeAndAbove,
  MediumAndBelow,
  VideoIframe
} from '../components'
import type { ProductLineProps } from '../components/ProductLine/ProductLine'
import { CurveBreakBottom } from '../components/Svg/CurveBreakBottom'
import { CurveBreakMiddle } from '../components/Svg/CurveBreakMiddle'

export interface IndexTemplateProps {
  seoTitle: string
  metaDescription: string
  hero: {
    heading: string
    background: string
  }
  intro: {
    heading: string
    subheading: string
    text: string
  }
  stats: Array<{
    heading: string
    subheading: string
  }>
  experience: Array<{
    text: string
    icon: string
    hoverIcon: string
    path: string
  }>
  productLine: ProductLineProps
  contact: {
    heading: string
    subheading: string
  }
}

const CustomerImageBox: React.FC<{
  href: string
  src: string
  alt: string
  mb?: Array<string>
}> = ({ href, src, alt, ...props }) => {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener"
      display="flex"
      width={[1 / 2, 1 / 2, 1 / 4]}
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Image src={src} alt={alt} maxWidth="90%" />
    </Link>
  )
}

const IndexPageTemplate: React.FC<IndexTemplateProps> = ({
  seoTitle,
  metaDescription,
  hero,
  intro,
  stats,
  experience,
  productLine,
  contact
}) => {
  const [videoIframeOpen, setVideoIframeOpen] = useState(false)

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Flex
        height={[600, 600, 700, 800]}
        alignItems="center"
        backgroundImage={`url('${hero.background}')`}
        backgroundSize="cover"
        backgroundPosition="center"
        position="relative"
      >
        <Image src={hero.background} alt="hero" display="none" />
        <Container>
          <Heading
            level="1"
            textTransform="uppercase"
            color="rgb(55, 100, 55)"
            textAlign="center"
            mb="xxxlarge"
          >
            {hero.heading}
          </Heading>
          <PlayButton onClick={() => setVideoIframeOpen(true)} />
        </Container>
        <CurveBreakBottom />
      </Flex>
      <Box pt="120" pb="xxlarge" bg="lime">
        <Container>
          <Flex wrap="wrap" mb="xxlarge">
            <Box width={[1, 1, 2 / 3]}>
              <Heading level="4" textTransform="uppercase" color="accent">
                {intro.subheading}
              </Heading>
              <Heading level="2" textTransform="uppercase" mb="medium">
                {intro.heading}
              </Heading>
              <Text size="small" whiteSpace="break-spaces">
                {intro.text}
              </Text>
            </Box>
            <LargeAndAbove width={1 / 3}>
              <Image
                src="/img/incobrasa-illinois-location.svg"
                alt="illinois-map"
                mx="auto"
                mb={-80}
              />
            </LargeAndAbove>
          </Flex>
          <Flex
            wrap="wrap"
            direction={['column-reverse', 'column-reverse', 'row']}
          >
            <Flex
              wrap="wrap"
              width={[1, 1, 1 / 2, 1]}
              mx={['none', 'none', 'none', 'xlarge']}
            >
              {stats.map((stat) => (
                <Blockquote
                  key={stat.heading}
                  width={[1, 1, 1, 1 / 3]}
                  mb="large"
                >
                  <Heading level="2" textTransform="uppercase" mb="xxsmall">
                    {stat.heading}
                  </Heading>
                  <Text size="small" weight="bold" textTransform="uppercase">
                    {stat.subheading}
                  </Text>
                </Blockquote>
              ))}
            </Flex>
            <MediumAndBelow width={[1, 1, 1 / 2]}>
              <Image
                src="/img/incobrasa-illinois-location.svg"
                alt="illinois-map"
                mx="auto"
                mb={['none', 'none', -40]}
              />
            </MediumAndBelow>
          </Flex>
        </Container>
      </Box>
      <Box position="relative">
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="50%"
          bg="lime"
        />
        <CurveBreakMiddle position="absolute" top="50%" left="0" />
        <Container>
          <Box width={['100%', '90%', '85%']} m="auto">
            <Box className="row">
              {experience.map(({ icon, hoverIcon, text, path }) => (
                <Box key={text} className="col col-12 col-sm-6 col-lg-3">
                  <Box
                    bg="white"
                    borderRadius="30"
                    px="xlarge"
                    py="xxlarge"
                    boxShadow="0px 10px 20px rgba(0, 0, 0, 0.1)"
                    transition="150ms"
                    mb="medium"
                    css={css`
                      &:hover {
                        transform: scale(1.05);
                        box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
                      }
                    `}
                  >
                    <Link
                      to={path}
                      textDecoration="none"
                      css={css`
                        .hover-icon {
                          display: none;
                        }
                        &:hover .icon {
                          display: none;
                        }
                        &:hover .hover-icon {
                          display: block;
                        }
                      `}
                    >
                      <Image
                        className="icon"
                        src={icon}
                        alt={icon}
                        width="68"
                        height="68"
                        mb="xxlarge"
                        mx="auto"
                      />
                      <Image
                        className="hover-icon"
                        src={hoverIcon}
                        alt={hoverIcon}
                        width="68"
                        height="68"
                        mb="xxlarge"
                        mx="auto"
                      />
                      <Text
                        size="large"
                        weight="bold"
                        textAlign="center"
                        color="secondary"
                      >
                        {text}
                      </Text>
                    </Link>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
      <Box py={[100, 125, 175]}>
        <Container>
          <ProductLine {...productLine} />
        </Container>
      </Box>
      <Box bg="lime" py="xxxlarge">
        <Container>
          <Text
            size="xlarge"
            color="primary"
            weight="bold"
            mb="xxlarge"
            textTransform="uppercase"
            textAlign="center"
          >
            Trusted By Our Valued Customers
          </Text>
          <Flex mx={['none', 'none', 'none', 'xlarge']} wrap="wrap">
            <CustomerImageBox
              href="https://www.pilgrims.com"
              src="/img/pilgrims.svg"
              alt="Pilgrims"
              mb={['xlarge', 'xlarge', 'none']}
            />
            <CustomerImageBox
              href="https://www.growmark.com"
              src="/img/growmark.svg"
              alt="Growmark"
              mb={['xlarge', 'xlarge', 'none']}
            />
            <CustomerImageBox
              href="https://www.familydollar.com"
              src="/img/family-dollar.svg"
              alt="Family Dollar"
            />
            <CustomerImageBox
              href="https://liparifoods.com"
              src="/img/lipari.svg"
              alt="Lipari"
            />
          </Flex>
        </Container>
      </Box>
      <Box py="100">
        <Container>
          <Heading level="4" textTransform="uppercase" color="accent">
            {contact.subheading}
          </Heading>
          <Heading
            level="2"
            textTransform="uppercase"
            mb={['large', 'large', 'large', 'xxxlarge']}
          >
            {contact.heading}
          </Heading>
          <Flex wrap="wrap">
            <Box width={[1, 1, 1, 1 / 2]}>
              <ContactForm />
            </Box>
            <Box
              width={[1, 1, 1, 1 / 2]}
              mt={['xxlarge', 'xxlarge', 'xxlarge', 'none']}
              pl={['none', 'none', 'none', 'xxlarge']}
            >
              <Box
                p="large"
                borderRadius="20"
                bg="white"
                boxShadow="0px 15px 30px rgba(0, 0, 0, 0.1)"
                textAlign="center"
              >
                <GoogleMap
                  address="540 East US Highway 24 Gilman, Illinois 60938"
                  borderRadius="8"
                  mb="small"
                  height="180"
                />
                <Text size="xlarge" weight="bold">
                  Incobrasa Industries, LTD.
                </Text>
                <Text size="small" mb="xxsmall">
                  540 East US Highway 24 Gilman, Illinois 60938
                </Text>
                <Text size="small" weight="bold">
                  (815) 265-4803
                </Text>
                <Button
                  variant="secondary"
                  textTransform="uppercase"
                  mx="auto"
                  mt="large"
                  py="medium"
                  size="large"
                  width="290"
                  maxWidth="100%"
                  target="_blank"
                  href="https://www.google.com/maps/place/Incobrasa+Industries+Ltd/@40.76202,-88.0168014,17z/data=!3m1!4b1!4m5!3m4!1s0x880da540e54ae441:0x68d79bc98a1c1b3a!8m2!3d40.762016!4d-88.0146074"
                >
                  Get Directions
                </Button>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
      {videoIframeOpen && (
        <VideoIframe
          src="https://player.vimeo.com/video/351036307?autoplay=1&loop=1&autopause=0"
          onRequestClose={() => setVideoIframeOpen(false)}
        />
      )}
    </>
  )
}

interface IndexPageProps {
  data: {
    markdownRemark: {
      frontmatter: IndexTemplateProps
    }
  }
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout transparentNavbarOnTop>
      <IndexPageTemplate {...frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      frontmatter {
        seoTitle
        metaDescription
        hero {
          heading
          background
        }
        intro {
          heading
          subheading
          text
        }
        stats {
          heading
          subheading
        }
        experience {
          text
          icon
          hoverIcon
          path
        }
        productLine {
          heading
          subheading
          items {
            heading
            subheading
            path
            image
          }
        }
        contact {
          heading
          subheading
        }
      }
    }
  }
`

export { IndexPageTemplate }
export default IndexPage
