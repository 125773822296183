import React from 'react'
import { Svg } from '..'
import type { SvgProps } from './Svg'

export const CurveBreakMiddle: React.FC<SvgProps> = ({
  fill = '#f4f8f2',
  ...props
}) => {
  return (
    <Svg
      width="100%"
      viewBox="0 0 1440 141"
      fill="none"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        d="M1069 62C1277.5 62 1381.59 19.1795 1440 16.2381V0H0V80C16.7365 73.9275 78.0251 57.7973 189.289 41.8568C328.368 21.9313 488.975 41.8568 597 41.8568C707.151 41.8568 881.709 62 1069 62Z"
        fill={fill}
      />
    </Svg>
  )
}
